<template>
  <div >
     <!--篩選區塊-->
     <el-row>


       <!--div style="float: right;">
        <span style="font-weight: bold; font-size: 1.2em;">請輸入發票開立日期:</span>
          <el-date-picker
             v-model="dateStart"
             size="small"
             class="filter-item"
             type="date"
             value-format="yyyy-MM-dd"
             :start-placeholder="listQuery.startOn"
             >
           </el-date-picker>
      </div-->
      <div style="float: left;">
        <span style="font-weight: bold; font-size: 1.2em;">比對月份:</span>
        <!--
        <el-input size="small" v-model="listQuery.platForm" placeholder="收費管道" class="filter-item" clearable /> -->
        <el-select size="small" v-model="listQuery.month" :placeholder="currentMonthLabel"  class="filter-item" clearable>
          <el-option
            v-for="item in months"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div style="float: left;">
        <span style="font-weight: bold; font-size: 1.2em;">來源:</span>
        <!--
        <el-input size="small" v-model="listQuery.platForm" placeholder="收費管道" class="filter-item" clearable /> -->
        <el-select size="small" v-model="listQuery.platForm" placeholder="收費管道" class="filter-item" clearable>
          <el-option
            v-for="item in platforms"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-button
       style="float: right; margin: 0px"
       size="small"
       type="primary"
       icon="el-icon-search"
       @click="getSummary()"      
     >
       查詢
     </el-button>

     </el-row>
     <el-row style="text-align: left; font-size: .8em; padding-right: 10px; color: dimgrey; padding-left: 10px; margin-bottom: 5px;" >
            <span  v-if="reportDate" >產製時間: {{ reportDate }} </span>
        </el-row>
     <!-- table -->

<div style= "margin: 50px 0;">
  
    <el-row> 
     <el-table   
       size="mini"
       v-loading="listLoading" :data="list" v-if="total > 0" style="width: 100%;" :cell-style="{padding: '0', height: '40px'}"
       @sort-change="tableSortChange"
     >     
       <!--<el-table-column align="center" prop="" min-width="30px"        label="No." /> -->
       <el-table-column align="center" prop="event_no" label="事件編號" />
       <el-table-column align="center" prop="event_datem"  label="事件產生日"/>
       <el-table-column align="center" prop="platform"   label="來源" />
       <el-table-column align="center" label="發票明細、對帳差異" >
        <template slot-scope="scope">
          <el-button  type="success" size="mini" icon="el-icon-setting"   @click="getDetail(scope.row)" />         
         </template>
      </el-table-column>
        
     </el-table> 
 
     <!--分頁組件-->
     <pagination v-show="total>0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size" @pagination="getList" />
</el-row> 
  </div>
  <!-- dialog-->
  <el-dialog width="1000px"  :visible.sync="dialogDetail" @close="dialogOnClose()" >
    <el-button
          style="float: left;"
          size="small"
          type="primary"
          icon="el-icon-tickets"
          v-if="(listXls1!=undefined&&listXls1!=null&&listXls1.length>0)&&(listXls1==undefined||listXls1==null||listXls1.length<=0)"
          @click="exportReport()"
        >{{$t('ExportReport')}}
       </el-button>
   
       <div style="float: left;">
       <!--listAll Export to XLSX-->
       <excel-xlsxes
         :data1="listXls1" :columns1="xlsxColumns1" :file-name="filenameAllXlsx" :file-type="'xlsx'" :sheet-name1="'sheetname1'"
         :data2="listXls2" :columns2="xlsxColumns2" :sheet-name2="'sheetname2'" :columns3="xlsxColumns3" :hasRefundInvoiceAmount="hasRefundInvoiceAmount" 
         v-if="listXls1!=undefined&&listXls1!=null&&listXls1.length>0"
         >
         <el-button type="success" size="mini" icon="el-icon-download" style="display: inline-block" @click="logOperate('report','/account-report/difference','EXPORT','匯出充電樁每月差額統計報表 xlsx')">{{$t('DownloadAllDataXlsx')}}</el-button>
       </excel-xlsxes>
       <!--listAll Export to XLSX-->
       </div>
<div style= "margin: 50px 0;">
  <el-row style="text-align: left; font-size: .8em; padding-right: 10px; color: dimgrey; padding-left: 10px; margin-bottom: 5px;" >
            <span  v-if="reportDate1" >產製時間: {{ reportDate }} </span>
        </el-row>
<el-row> 
  <div>
<el-table   
size="mini"
v-loading="listLoading" :data="listXls1" v-if="invoiceTotal > 0" style="width: 100%;" :cell-style="{padding: '0', height: '40px'}"
@sort-change="tableSortChange"
>     
<!--<el-table-column align="center" prop="" min-width="30px"        label="No." /> -->
<el-table-column align="center" prop="invoice_number" label="發票號碼" />
<el-table-column align="center" prop="invoice_amount"  label="交易金額"/>
<el-table-column align="center" prop="settle_date"   label="扣款日" />
<el-table-column align="center" prop="invoice_deposit_amount" label="扣款金額" />
  <el-table-column align="center" prop="invoice_charge" label="手續費" />
    <el-table-column align="center" prop="platform" label="來源" />
      <el-table-column align="center" prop="event_date" label="事件產生日" />
</el-table> 
</div>
<br>
<div>
<el-table   
size="mini" 
v-loading="listLoading" :data="listXls2" v-if="eventTotal > 0" style="width: 100%;" :cell-style="{padding: '0', height: '40px'}"
@sort-change="tableSortChange"
>     
<!--<el-table-column align="center" prop="" min-width="30px"        label="No." /> -->
<el-table-column align="center" prop="settle_date" label="入帳日" min-width="100px"/>
<el-table-column align="center" prop="agent_amount"  label="請款金額"/>
<el-table-column align="center" prop="agent_deposit_amount"   label="撥款金額" />
<el-table-column align="center" prop="agent_charge" label="手續費" />
<el-table-column align="center" prop="invoice_amount"  label="平台報表請款金額"/>
<el-table-column align="center" prop="invoice_deposit_amount"   label="平台報表撥款金額" />
<el-table-column align="center" prop="invoice_charge" label="平台報表手續費" />
<el-table-column align="center" prop="diff_one" label="撥款差額" />
<el-table-column align="center" v-if="hasRefundInvoiceAmount" prop="refund_invoice_amount" label="已開發票的刷退金額" />
<el-table-column align="center" v-if="hasRefundInvoiceAmount" prop="refund_invoice_charge" label="已開發票的刷退手續費" />
<el-table-column align="center" v-if="hasRefundInvoiceAmount" prop="diff_two" label="撥款差額2(撥款差額減已開發票刷退)" />
<el-table-column align="center" prop="platform" label="來源" />
<el-table-column align="center" prop="event_date" label="事件產生日" min-width="100px"/>
</el-table> 
</div>
<!--分頁組件
<pagination v-show="total>0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size" @pagination="getList" />
-->
</el-row> 
</div>
</el-dialog>
   </div>
 </template>
 
 <script>
 import Pagination from '@/components/Pagination';
import { mixins } from '@/views/common/mixins.js';
 /* 每隔10min刷新出jwt token*/
 
 
 export default {
     name: 'Status',
     components: {
         Pagination
     },
     mixins: [mixins],
     data() {
         return {
             dialogWidth: 0,
             total: 0,
             invoiceTotal: 0,
             eventTotal: 0,
             currentMonthLabel    : '',
             dialogDetail     : undefined,
             connectorOption  : undefined,
             chargePointOption:undefined,
             platforms: [{
                value: 'TapPay',
                label: 'TapPay'
              }, {
                value: 'LinePay',
                label: 'LinePay'
              }],
             months: [],
             list: [],
             statisticsData: '',
             listDetail: [],
             listXls1: [],
             listXls2: [],
             listXls3: [],
             listLoading: false,
             hasRefundInvoiceAmount: false,
             listQuery: {

                 year: undefined,
                 month: undefined,// 
                 platForm:undefined,
                 eventNo:undefined,
                 page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
                 size: 15,
                 //sort: "customer_name,asc",
             },

             size           : undefined,
             paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
             dateStart: undefined,
             queryDays: undefined,
             dialogSettingVisible: false,
             targetUrl:undefined,
             reportDate: undefined,
             reportDate1: undefined,
             json_fields: {
               "No"   : "no",
               "StationName"     : "stationName",
               "PointName"   : "pointName",
               "Count" : "count",
               "UsageRate"     : "usageRate",
             },
                 xlsxColumns1 : [
                 {
                    // label: this.$t('ChargingConnector'),
                    label: '發票號碼',
                     field: "invoice_number",
                 },
                 {
                     //label: this.$t('Account'),
                     label: '交易金額',
                     field: "invoice_amount",
                 },
                 {
                     //label: this.$t('Start')+this.$t('Time'),
                     label: '扣款日',
                     field: "settle_date",
                 },
                 {
                     //label: this.$t('StartDegree'),
                     label: '扣款金額',
                     field: "invoice_deposit_amount",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '手續費',
                     field: "invoice_charge",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '來源',
                     field: "platform",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '事件產生日',
                     field: "event_date",
                 },
                 ],
                 xlsxColumns2 : [
                 {
                    // label: this.$t('ChargingConnector'),
                    label: '入帳日',
                     field: "settle_date",
                 },
                 {
                     //label: this.$t('Account'),
                     label: '請款金額',
                     field: "agent_amount",
                 },
                 {
                     //label: this.$t('Start')+this.$t('Time'),
                     label: '撥款金額',
                     field: "agent_deposit_amount",
                 },
                 {
                     //label: this.$t('StartDegree'),
                     label: '手續費',
                     field: "agent_charge",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '平台報表請款金額',
                     field: "invoice_amount",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '平台報表撥款金額',
                     field: "invoice_deposit_amount",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '平台報表手續費',
                     field: "invoice_charge",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '撥款差額',
                     field: "diff_one",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '已開發票的刷退金額',
                     field: "refund_invoice_amount",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '已開發票的刷退手續費',
                     field: "refund_invoice_charge",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '撥款差額2(撥款差額減已開發票刷退)',
                     field: "diff_two",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '來源',
                     field: "platform",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '事件產生日',
                     field: "event_date",
                 },
                 ],
                 xlsxColumns3 : [
                 {
                    // label: this.$t('ChargingConnector'),
                    label: '入帳日',
                     field: "settle_date",
                 },
                 {
                     //label: this.$t('Account'),
                     label: '請款金額',
                     field: "agent_amount",
                 },
                 {
                     //label: this.$t('Start')+this.$t('Time'),
                     label: '撥款金額',
                     field: "agent_deposit_amount",
                 },
                 {
                     //label: this.$t('StartDegree'),
                     label: '手續費',
                     field: "agent_charge",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '平台報表請款金額',
                     field: "invoice_amount",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '平台報表撥款金額',
                     field: "invoice_deposit_amount",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '平台報表手續費',
                     field: "invoice_charge",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '撥款差額',
                     field: "diff_one",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '來源',
                     field: "platform",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '事件產生日',
                     field: "event_date",
                 },
                 ],
             /* 匯出檔名 */
             //filenameCsv:     this.$moment().format("YYYYMMDD-HHmmss").toString() + "_UsageReport" + ".xls",
             //filenameAll:     this.$moment().format("YYYYMMDD-HHmmss").toString() + "_UsageReportAll" + ".xls",
             filenameAllXlsx: this.$moment().format("YYYYMMDD-HHmmss").toString() + "_DifferenceAll",
      
       // chartDataExist : false, // 是否有圖表資料
         //   xAxisData      : [],    // x軸 日期時間
           // yAxisData      : [],    // y軸  
           // y2AxisData     : [],    // y軸2        
         };
     },
   created() {
     //定時刷新
     //refreshToken();
 
     this.dialogWidth = this.setDialogWidth()
     this.getPreviousThreeMonths() 
    // this.getStationByServiceId();
 
     
     //if(this.$route.params.stationId != undefined) 
    // {
     // this.chargePointOption = this.$route.params.stationId;   
    //}
     //this.debounceGetList(this.getListAll, 2000) // 監聽輸入框，一有變化就送請求
     //this.resetPage(this.getList);
   },
   mounted() {
     window.onresize = () => {
       return (() => {
         this.dialogWidth = this.setDialogWidth()
       })()
     }
   },
   beforeDestroy() {
     console.log("Call stopRefreshToken");
     //stopRefreshToken();
   },
   watch: {
     paginationSelectedPage: {
       handler: function(newValue) {
         this.listQuery.page = newValue - 1
       }
     },

     /* 偵測若語系切換,則進行本組件切出去在切回來,以達本組件語系包含子組件(含對話視窗)都刷新 */
     '$i18n.locale': function(val) {
       console.log('Role組件重載前')
       this.$router.push( { path:'/refresh',query:{ fromPath:this.$route.fullPath } })
       console.log('Role組件重載完成')
       },
     /* ----------------------------------------------------------------------------- */

    'listQuery.platForm': function (val) {
       this.listQuery.page = 0;
       this.listQuery.platForm = val;
      // this.resetPage(this.getList);
      this.reportDate = undefined;
      this.reportDate1 = undefined;
      this.list= undefined;
       this.listXls1= undefined;
       //this.connectorOption=null;
      },
      
    'listQuery.month': function (val) {
       this.listQuery.page = 0;
       if(val){
        this.listQuery.month = val;
       }
       else{
        this.listQuery.month = undefined;
       }
      // this.resetPage(this.getList);
      this.reportDate = undefined;
      this.reportDate1 = undefined;
      this.list= undefined;
       this.listXls1= undefined;
       //this.connectorOption=null;
      },
      },
   computed: {
    
  },
   methods: {
    exportToCSV() {
    //  this.logOperate('report','/report/usage','EXPORT','匯出充電樁使用率統計報表 csv');

const csvContent = this.convertToCSV(this.listCsv); //utf-8 

const link = document.createElement("a");


// "\ufeff"是为了解决CSV中文
let blob = new Blob(["\ufeff" +csvContent],{type: 'text/csv;charset=utf-8;'});
let url = URL.createObjectURL(blob);
link.href = url;
link.setAttribute('download', this.filenameAllXlsx);
link.click();
},
convertToCSV(data) {
const columnDelimiter = ",";
const lineDelimiter = "\n";
const keys = Object.keys(data[0]);

let result = "";
result += keys.join(columnDelimiter);
result += lineDelimiter;

data.forEach(item => {
  let row = "";
  keys.forEach(key => {
    row += item[key] + columnDelimiter;
  });
  result += row.slice(0, -1);
  result += lineDelimiter;
});

return result;
},

     getList() {
     
       let number = this.page
       let size   = this.size

       this.listLoading = true

      this.targetUrl ="/api/v1/report/RequestPayment"; //?startOn=2024-07-28
 
       //this.axios.get('/api/v1/report/status', {params: this.listQuery}).then(res=> {
        this.axios.get(this.targetUrl, {params: this.listQuery}).then(res => {
         //console.log("Response:/api/v1/report/status [res.data]",res.data)
         this.list = res.data.content
         //this.listXls = res.data.content
         this.total = res.data.totalElements
         //this.list = this.contentList
         number = res.data.number
         size   = res.data.size
         /*
         this.listCsv = res.data.content
  
         this.listCsv =  this.listXls.map(( item , index ) => {
          item.no = index + number*size +1     
        return {
          編號: item.no,
          充電站: item.stationName,
          充電樁: item.pointName,
          使用次數: item.count,
          使用率: this.timeToSeconds(item.chargetime) + "("+item.chargetime+")"
        };
      });
      */
         /* 讓 this.list的Map加入一個key為no */
         let output = this.list.map( ( item , index ) => {
             item.no = index + number*size +1          
             return item
         })         
       })

       this.targetUrl ="/api/v1/report/RequestPaymentTotal"; //?startOn=2024-07-28

this.axios.get(this.targetUrl, {params: this.listQuery}).then(res => {
  this.statisticsData = res.data
 
 // console.log(this.statisticsData)
})  
this.exportReport()
this.listLoading = false
     },

       exportReport(){

       this.size          = this.listQuery.size
       this.listQuery.page=0
       this.listQuery.size=100000
       let number = this.page
       let size   = this.size
       this.targetUrl ="/api/v1/report/DiffEventDetail"; //?startOn=2024-07-28
 

  this.axios.get(this.targetUrl, {params: this.listQuery}).then(res => {
   this.listXls = res.data.content
   number = res.data.number
   size   = res.data.size

   let output = this.listXls.map( ( item , index ) => {
       item.no = index + number*size +1             
       return item
   }) 
   /*
   const newItem = {
        invoice_number: '',
        invoice_amount: '',
        settle_date: '',
        invoice_deposit_amount: '',
        invoice_charge: '',
        platform: '',
        event_date: '',
      };
      this.listXls.push(newItem);       */ 
 })
       this.listQuery.size=this.size
       //this.filenameCsv =    this.$moment().format("YYYYMMDD-HHmmss").toString() + "_UsageReport" + ".xls"
       //this.filenameAll =    this.$moment().format("YYYYMMDD-HHmmss").toString() + "_UsageReportAll" + ".xls"
       this.filenameAllXlsx= this.$moment().format("YYYYMMDD-HHmmss").toString() + "_InvoiceAll"
       //this.listQuery.size=15
     },   
    
    getPreviousThreeMonths() {

      const currentMonth = new Date().getMonth() + 1;
      this.listQuery.year = new  Date().getFullYear();
      //console.log(this.listQuery.year )
      this.currentMonthLabel = `${currentMonth}月`;
      
      for (let i = 0; i < 4; i++) {
        const monthValue = currentMonth - i;

        if (monthValue > 0) {
          this.months.push({ label: `${monthValue}月`, value: monthValue });
        }
      }
 
    },
    getSummary(){
      this.reportDate = this.formatCurrentDateTime()
      //http://localhost:6783/api/v1/report/DiffEventSummary?year=2024&month=9&platform=tappay&page=0&size=15&sort=event_date,desc
      let number = this.page
       let size   = this.size

       this.listLoading = true

      this.targetUrl ="/api/v1/report/DiffEventSummary"; //?startOn=2024-07-28
 
       //this.axios.get('/api/v1/report/status', {params: this.listQuery}).then(res=> {
        this.axios.get(this.targetUrl, {params: this.listQuery}).then(res => {
         //console.log("Response:/api/v1/report/status [res.data]",res.data)
         this.list = res.data.content
         //this.listXls = res.data.content
         this.total = res.data.totalElements
         //this.list = this.contentList
         number = res.data.number
         size   = res.data.size
         res.data.content.forEach(diff => {
          diff.event_datem=diff.event_date.split(' ')[0]
            console.log(diff.event_datem)
         })
        //console.log(this.list)
        // let output = this.list.map( ( item , index ) => {
         //    item.no = index + number*size +1             
          //   return item
         //})         
       })
       this.listLoading = false
    }, //
    async getDetail(row){
      this.reportDate1 = this.formatCurrentDateTime();
      this.dialogDetail=true
      //http://localhost:6783/api/v1/report/DiffEventSummary?year=2024&month=9&platform=tappay&page=0&size=15&sort=event_date,desc
      let number = this.page
       let size   = this.size
      let year  =this.listQuery.year
       this.listLoading = true
      this.listQuery.eventNo= row.event_no
      console.log( "event_no",row.event_no)
      this.targetUrl ="/api/v1/report/DiffEventDetail"; //?startOn=2024-07-28
       
       //this.axios.get('/api/v1/report/status', {params: this.listQuery}).then(res=> {
        await this.axios.get(this.targetUrl, {params: this.listQuery}).then(res => {
         //console.log("Response:/api/v1/report/status [res.data]",res.data)
         this.listDetail = res.data
         //this.listXls = res.data.content
         //this.total = res.data.totalElements
         //this.list = this.contentList
        // number = res.data.number
        // size   = res.data.size
       //  res.data.content.forEach(diff => {
       //   diff.event_datem=diff.event_date.split(' ')[0]
        //    console.log(diff.event_datem)
        // })
        //console.log(" this.listDetail ", this.listDetail )
        this.listXls1 = this.listDetail.invoicedetail.content
        this.listXls2 = this.listDetail.eventdetail.content
        //this.listXls1 = this.listDetail.invoiceTotal
        this.eventTotal = this.listDetail.eventdetail.totalElements
        this.invoiceTotal = this.listDetail.invoicedetail.totalElements
        //onsole.log("event:",this.listXl2)
        //this.hasRefundInvoiceAmount = false;
        //console.log("this.listXls2.refund_invoice_amount:",this.listXls2[0].refund_invoice_amount)
        /*
        if(this.listXls2  != undefined &&  this.listXls2.refund_invoice_amount != undefined && this.listXls2.refund_invoice_amount != null){
          this.hasRefundInvoiceAmount = true;
        }
        else{
          this.hasRefundInvoiceAmount = false;
        }
        */

        var case2Sum  = 0
        var case2diff = 0

        this.listXls1.forEach( item =>
         {
          item.event_date = item.event_date.split(" ")[0] //去除掉時間
          item.settle_date = item.settle_date.split(" ")[0] //去除掉時間           
         }
        )

        this.listXls2.forEach( item =>
         {
          case2Sum  = case2Sum + item.refund_invoice_amount + item.refund_invoice_charge + item.diff_two
          case2diff = case2diff + item.diff_one
          item.event_date = item.event_date.split(" ")[0] //去除掉時間
          item.settle_date = item.settle_date.split(" ")[0] //去除掉時間           
         }
        )
        if(this.listXls2  != undefined &&  case2Sum != 0 &&  case2diff != 0){
          this.hasRefundInvoiceAmount = true;
        }
        else{
          this.hasRefundInvoiceAmount = false;
        }
        console.log("hasRefundInvoiceAmount:",this.hasRefundInvoiceAmount)
        // let output = this.list.map( ( item , index ) => {
         //    item.no = index + number*size +1             
          //   return item
         //})         
       })
       this.listLoading = false
    },
    dialogOnClose(){
          
         },
 },
   
}
 
 </script>
 